


/* Background style for public content section */
.public-content {
  width: 100%;
  padding: 100px 15px;
  margin: 0 auto;
}

/* Scrolling text animation */
.scrolling-text {
  white-space: nowrap;
  overflow: hidden;
  animation: scroll 10s linear infinite;
}

/* Improved animated multicolor text */
.animated-text {
  font-size: 2rem;
  text-align: center;
  padding: 10px 0;
}

.multicolor-text {
  display: inline-block; /* Ensure the gradient applies only to the text */
  background: linear-gradient(90deg,white, white,darkred,white,white,white,darkred,darkred);
  background-size: 300%; /* Increase the size for smoother animation */
  -webkit-background-clip: text; /* For webkit-based browsers like Chrome */
  background-clip: text;
  color: transparent;
  animation: colorScroll 5s linear infinite;
}

@keyframes colorScroll {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

/* Form input field and button styles */
#key-name {
  border-radius: 25px;
  padding: 10px;
}

button {
  border-radius: 25px;
  background-color: #ff8c00;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #ff5c00;
}

